import * as React from 'react';

function DownloadIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 18 20" {...props}>
      <g
        stroke="#FFF"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M11.236.762H4.584C2.525.762.75 2.43.75 4.49v10.737c0 2.176 1.658 3.887 3.834 3.887h7.988c2.06 0 3.73-1.827 3.73-3.887v-9.19L11.236.762z" />
        <path d="M10.974.75v2.91a2.574 2.574 0 002.569 2.574c1.316.003 2.663.004 2.754-.002m-8.156 7.782V7.441m-2.839 3.722l2.838 2.851 2.839-2.851" />
      </g>
    </svg>
  );
}

export default DownloadIcon;
