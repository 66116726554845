import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Container from 'components/Container';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ga, useGState } from 'state/store';
import { default as fetch, default as fetchPromise } from 'utils/fetch';
import HoldRetryCard from '../execution/HoldRetryCard';

const useStyles = makeStyles(() => ({
  save: {
    marginTop: 20,
    background: '#4caf4f',
    color: 'white',
    '&:hover': {
      background: '#4caf4f',
    },
  },
}));

function HoldRetry() {
  const classes = useStyles();

  const [holdRetryCards, setHoldRetryCards] = useState([]);
  const [stateChanges, setStateChanges] = useState([]);
  const [finalStateChanges, setFinalStateChanges] = useState([]);
  const [error, setError] = useState(false);

  const { branch } = useGState((s) => ({
    branch: s.branch,
  }));

  useQuery(
    ['holdRetry', { branch }],
    (_, { branch }) => fetchPromise(`v3/delivery/panel/return?nodeId=${branch.id}`),
    {
      onSuccess: (data) => {
        setHoldRetryCards(data);
        setStateChanges(data.map((item) => ({ invoiceId: item.id, toReturnStatus: item.state })));
      },
    }
  );

  const [saveMultiInvoices] = useMutation(
    (finalStateChanges) =>
      fetch({
        method: 'POST',
        url: `/v3/delivery/panel/multi-return?nodeId=${branch.id}`,
        data: finalStateChanges,
      }),
    {
      onSuccess: () => {
        const final = holdRetryCards.filter((list1) =>
          finalStateChanges.some(
            (list2) => list1.id == list2.invoiceId && (list1.state = list2.toReturnStatus)
          )
        );

        const finalStates = holdRetryCards.filter((o1) =>
          final.some((o2) => (o1.id !== o2.id ? o1 : o2))
        );

        setHoldRetryCards(finalStates);
        setFinalStateChanges([]);
        setError(false);
      },
      onError: () => {
        setError(true);
        setFinalStateChanges([]);
      },
    }
  );

  const saveChanges = () => {
    finalStateChanges.length != 0 && saveMultiInvoices(finalStateChanges);
  };

  const onStateChange = (changedState, id) => {
    let newState = stateChanges.find((item) => item.invoiceId == id);
    newState.toReturnStatus = changedState;
    setStateChanges(stateChanges.filter((item) => (item.invoiceId == id ? newState : item)));
    setFinalStateChanges(
      stateChanges.filter((o1) =>
        holdRetryCards.some((o2) => o1.invoiceId == o2.id && o1.toReturnStatus != o2.state)
      )
    );
  };

  const removeError = () => {
    setError(false);
  };

  return (
    <>
      {holdRetryCards && (
        <Button
          variant="contained"
          className={classes.save}
          onClick={saveChanges}
          disabled={finalStateChanges.length == 0 ? true : false}
        >
          Save
        </Button>
      )}
      {holdRetryCards.map((data) => (
        <HoldRetryCard
          key={data.id}
          data={data}
          barnchId={branch.id}
          onStateChange={onStateChange}
          error={error}
          removeError={removeError}
        />
      ))}
    </>
  );
}

export default function ManageHoldRetry({ closeTab }) {
  const settings = useGState((s) => s[ga.CLIENT_PREFERENCE]);
  const returnTerminology = settings.returnTerminology;
  return (
    <Container>
      <div>
        <div class="my-4 text-dark_blue font-medium flex justify-between">
          <Typography>{`${returnTerminology?.HOLD || 'HOLD'} / ${
            returnTerminology?.RETRY || 'RETRY'
          }`}</Typography>
          <CloseIcon className="cursor-pointer" onClick={closeTab} />
        </div>
        <HoldRetry />
      </div>
    </Container>
  );
}
