import Button from 'components/inputs/ButtonM';
import React from 'react';
import { useGState } from 'state/store';
import { useSnackbar } from 'utils/customHooks.js';
import Uploader from './Uploader.js';

const style = {
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  height: 'inherit',
  cursor: 'pointer',
};

export default function InvoiceUpdateDetails({ uploaderId, refetch }) {
  const [notif] = useSnackbar();

  const { branch } = useGState((s) => ({
    branch: s.branch,
  }));
  return (
    <div>
      <p className="text-xl font-medium mb-2">Invoice Update</p>
      <div className="flex mt-6 justify-start gap-3 items-center">
        <Uploader
          url={`/invoice/wms?nodeId=${branch.id}&orderUploadId=${uploaderId}`}
          onSuccess={() => {
            refetch();
            notif('Invoice Updated Successfully');
          }}
          style={style}
        />
        <div>
          <Button
            className="w-max"
            variant="text"
            component="a"
            href={`/api/invoice/wms/template?nodeId=${branch.id}`}
          >
            Download Sample
          </Button>
        </div>
      </div>
    </div>
  );
}
