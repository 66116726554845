import CircularProgress from '@material-ui/core/CircularProgress';
import ConfirmationDialog from 'components/ConfirmationDialog';
import React, { useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import fetch from 'utils/fetch';

export default function Uploader({ style, url, onSuccess = () => {}, dialog }) {
  const dialogRef = useRef();
  const { title, yes, no, onYes, onNo } = dialog ?? {};

  const [uploadInvoice, { status }] = useMutation(
    ({ data, urlSuffix = '' }) =>
      fetch({
        url: url + urlSuffix,
        method: 'POST',
        data,
      }),
    {
      onSuccess: onSuccess,
    }
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (files) => {
      const data = new FormData();
      data.append('file', files[0]);
      if (dialog) {
        dialogRef.current.openDialog(data);
      } else {
        uploadInvoice({ data });
      }
    },
  });

  return (
    <div class="h-40 items-center mb-2">
      {dialog ? (
        <ConfirmationDialog
          title={title}
          ref={dialogRef}
          yes={yes}
          no={no}
          onYes={(data) => {
            uploadInvoice({ data, urlSuffix: onYes() });
          }}
          onNo={(data) => {
            uploadInvoice({ data, urlSuffix: onNo() });
          }}
        />
      ) : null}

      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag and drop some files here, or click to select files</p>
        {status === 'loading' && (
          <div class="mt-3">
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
}
